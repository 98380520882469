import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslate } from 'ra-core';
import { useState } from 'react';
import { Title, useRedirect } from 'react-admin';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import config from './config';

import { ArrowBack, Check, Error, FileCopy, QrCode, Download } from '@mui/icons-material';
import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, Paper,
  Stack, TextField, Typography
} from '@mui/material';

import { useFetchData } from '../utils/useAdminApi';
import { InviteCodeModel } from './model';

dayjs.extend(LocalizedFormat)
require('dayjs/locale/fr');


const environment = String(process.env.REACT_APP_ID);

export const CodeProgramShow = (props) => {
  // Load translation & redirection
  const translate = useTranslate();
  const redirect = useRedirect();

  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  // Get and set locale
  let locale = translate('resources.locale.prefix');
  if (locale === 'resources.locale.prefix') locale = 'fr-FR'; // Set default locale if undefined in i18n
  dayjs.locale(locale);

  // Get programId
  const { programId, id: inviteCodeId } = useParams();

  // Fetch invite code data
  const { data: inviteCodeData, loading: fetchingCodeData, error: codeFetchError } = useFetchData("/adminApi/program/" + programId + "/invitecodes/" + inviteCodeId) as { data: InviteCodeModel, loading: boolean, error: any };

  // const inviteCodeHash = JSON.stringify(inviteCodeData);
  if (fetchingCodeData || !inviteCodeData) { return <CircularProgress size={25} thickness={2} />; }

  const url = process.env.REACT_APP_ID == 'alixmed' ? `https://launcher.med.alixforme.com/invite/${inviteCodeData.id}` : `https://launcher.alixforme.com/invite/${inviteCodeData.id}`

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess('success');
      setTimeout(() => setCopySuccess(''), 5000);
    }, () => {
      setCopySuccess('error');
      setTimeout(() => setCopySuccess(''), 5000);
    });
  };

  const downloadQRCode = () => {
    const svg = document.getElementById("qr-code");
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        if(ctx) {
          const marginRatio = 0.05; // 5% margin
          const marginX = img.width * marginRatio;
          const marginY = img.height * marginRatio;
  
          canvas.width = img.width + 2 * marginX;
          canvas.height = img.height + 2 * marginY;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = "white"; // Global background
          ctx?.fillRect(0, 0, canvas.width, canvas.height);

          ctx?.drawImage(img, marginX, marginY, img.width, img.height);
          const pngFile = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.download = `${programId}-${inviteCodeId}`;
          downloadLink.href = `${pngFile}`;
          downloadLink.click();
        }
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  }

  return (
    <Paper style={{ padding: 16, margin: "16px 8px" }}>
      {/* Go back button */}
      <div style={{ display: 'flow-root' }}>
        <Button sx={{ float: 'left' }} startIcon={<ArrowBack />} onClick={() => redirect('/' + programId + '/invite-codes')}>{translate('resources.misc.goBack')}</Button>
      </div>
      <Dialog
        open={qrCodeOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            spacing={1}>
            <Box sx={{ p: 2 }}>
              <QRCode
                size={1024}
                id="qr-code"
                style={{ height: "auto", width: "100%", maxWidth: 256, borderStyle: "solid", borderColor: "#FFF" }}
                value={url}
                viewBox={`0 0 1024 1024`}
              />
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" gap={0} sx={{ p: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                label={translate(`resources.codes.urlToCopy`)}
                value={url}
                size="small"
                inputProps={{
                  readOnly: true,
                }}
              />
              <IconButton
                color="primary"
                onClick={copyToClipboard}
              >
                {copySuccess === '' && <FileCopy color='primary' />}
                {copySuccess === 'success' && <Check color='success' />}
                {copySuccess === 'error' && <Error color='error' />}
              </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Button variant="contained" endIcon={<Download />} onClick={downloadQRCode}>
                {translate(`resources.codes.download`)}
              </Button>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQrCodeOpen(false)} autoFocus>{translate(`resources.misc.close`)}</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 16 }}>
        <Typography variant='h5'>{inviteCodeData.name}</Typography>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', rowGap: 16 }}>
          <Grid container spacing={8}>

            {/* Basic properties */}
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
              <TextField variant='standard' fullWidth label={translate('resources.codes.id')} value={inviteCodeData.id || '?'} InputProps={{ readOnly: true, disableUnderline: true, style: { textOverflow: 'ellipsis' } }} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.status')} value={inviteCodeData.codeStatus || 'unavailable'} InputProps={{ readOnly: true, disableUnderline: true }} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.createdAt')} value={dayjs(inviteCodeData.createdAt).format('LLLL') || '?'} InputProps={{ readOnly: true, disableUnderline: true }} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.updatedAt')} value={dayjs(inviteCodeData.updatedAt).format('LLLL') || '?'} InputProps={{ readOnly: true, disableUnderline: true }} />

            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
              <TextField variant='standard' fullWidth label={translate('resources.codes.uses')} value={inviteCodeData.withCounts ? inviteCodeData.uses || 0 : '-'} InputProps={{ readOnly: true, disableUnderline: true }} />
              {inviteCodeData.withCounts &&
                <TextField variant='standard' fullWidth label={translate('resources.codes.create.maxUses')} value={inviteCodeData.maxUses || 0} InputProps={{ readOnly: true, disableUnderline: true }} />
              }
              {inviteCodeData.withExpiration &&
                <TextField variant='standard' fullWidth label={translate('resources.codes.expiresAt')} value={dayjs.unix((inviteCodeData.expiresAt as number / 1000)).format('LLLL') || '?'} InputProps={{ readOnly: true, disableUnderline: true }} />
              }
              {inviteCodeData.withUserValidation &&
                <TextField variant='standard' fullWidth label={translate('resources.codes.validation')} value={translate(`resources.codes.${inviteCodeData.userValidationMethod}`) || '?'} InputProps={{ readOnly: true, disableUnderline: true }} />
              }
            </Grid>
          </Grid>
          <Button onClick={() => setQrCodeOpen(true)} variant="outlined" startIcon={<QrCode />}>{translate(`resources.codes.seeQrCode`)}</Button>
        </div>
      </div>
    </Paper>
  )
};

export default CodeProgramShow;