import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import JSONPretty from 'react-json-prettify';

import CloseIcon from '@mui/icons-material/Close';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { Button, DialogActions, DialogContent, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function RawSessionOutputDialog({
  session
}: {
  session: any
}) {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  if (!session) return <></>;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={translate('resources.sessions.jsonSource')} arrow placement="left">
        <Button variant='contained' color='primary' sx={{ minWidth: 0, padding: 1 }} onClick={handleClickOpen}>
          <IntegrationInstructionsIcon />
        </Button>
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {translate(`resources.sessions.generatedSession`)}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <JSONPretty json={session} padding={2} />
        </Box>
      </Dialog>
    </div >
  );

}