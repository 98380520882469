import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { FileDownload, Movie } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip } from '@mui/material';
import Form from '@rjsf/mui';
import {
    canExpand, descriptionId, FormContextType, getTemplate, getUiOptions, ObjectFieldTemplateProps,
    RJSFSchema, StrictRJSFSchema, titleId
} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

// react-jsonschema-form template
function ObjectFieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: ObjectFieldTemplateProps<T, S, F>) {
    const {
        description,
        title,
        properties,
        required,
        uiSchema,
        idSchema,
        schema,
        registry,
    } = props;
    const uiOptions = getUiOptions<T, S, F>(uiSchema);
    const TitleFieldTemplate = getTemplate<'TitleFieldTemplate', T, S, F>('TitleFieldTemplate', registry, uiOptions);
    const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate', T, S, F>(
        'DescriptionFieldTemplate',
        registry,
        uiOptions
    );

    return (
        <>
            {title && (
                <TitleFieldTemplate
                    id={titleId<T>(idSchema)}
                    title={title}
                    required={required}
                    schema={schema}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
            {description && (
                <DescriptionFieldTemplate
                    id={descriptionId<T>(idSchema)}
                    description={description}
                    schema={schema}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
            <Grid container={true} spacing={2} style={{ marginTop: '10px' }}>
                {properties.map((element, index) =>
                    <Grid item={true} xs={12} sm={6} md={4} lg={3} key={index} style={{ marginBottom: '10px' }}>
                        {element.content}
                    </Grid>
                )}
            </Grid>
        </>
    );
}

// Main component
export default function ExportVideoJSON({
    session
}: {
    session: any
}) {
    const translate = useTranslate();
    const [openJSONExport, setOpenJSONExport] = useState(false);

    const formSchema: RJSFSchema = {
        title: translate('resources.videoGenerator.title'),
        properties: {
            programId: { type: 'string', title: translate('resources.videoGenerator.programId'), default: session.program || session.name || '' },
            creator: {
                type: 'string', title: translate('resources.videoGenerator.creator'), default: "Default", enum: [
                    "Default",
                    "Stephane",
                    "Theo",
                    "Celestine",
                    "Pause_Alix",
                    "Plouescat",
                    "Sambo",
                    "Steeple"
                ]
            },
            avatar: {
                type: 'string', title: translate('resources.videoGenerator.avatar'), default: "nina", enum: [
                    'nina',
                    'alex'
                ]
            },
            languages: {
                type: 'array', title: translate('resources.videoGenerator.languages'), uniqueItems: true, default: ["French"], items: {
                    type: 'string', enum: [
                        'French',
                        'English',
                        'Dutch'
                    ]
                }
            },
            portraitMode: { type: 'boolean', title: translate('resources.videoGenerator.portraitMode'), default: false },
            UHDExerciseVideos: { type: 'boolean', title: translate('resources.videoGenerator.UHDExerciseVideos'), default: false },
            sessionNumber: {
                type: 'integer', title: translate('resources.videoGenerator.sessionNumber'),
                default: session.components ? session.components[0].session || 1 : 1
            },
            introCreditDuration: { type: 'number', title: translate('resources.videoGenerator.introCreditDuration'), default: 5 },
            outroCreditDuration: { type: 'number', title: translate('resources.videoGenerator.outroCreditDuration'), default: 5 },
            introAvatarDuration: { type: 'number', title: translate('resources.videoGenerator.introAvatarDuration'), default: 10 },
            outroAvatarDuration: { type: 'number', title: translate('resources.videoGenerator.outroAvatarDuration'), default: 10 },
            withPreview: { type: 'boolean', title: translate('resources.videoGenerator.withPreview'), default: true },
            withBHLogo: { type: 'boolean', title: translate('resources.videoGenerator.withBHLogo'), default: true },
            withWhiteAlixLogo: { type: 'boolean', title: translate('resources.videoGenerator.withWhiteAlixLogo'), default: false },
            withSecondLogo: { type: 'boolean', title: translate('resources.videoGenerator.withSecondLogo'), default: false },
            withAdvice: { type: 'boolean', title: translate('resources.videoGenerator.withAdvice'), default: false },
            withScrollingText: { type: 'boolean', title: translate('resources.videoGenerator.withScrollingText'), default: false },
            withPauseAlixVoiceOver: { type: 'boolean', title: translate('resources.videoGenerator.withPauseAlixVoiceOver'), default: false },
            musicIntensity: { type: 'number', title: translate('resources.videoGenerator.musicIntensity'), enum: [0, 1, 2, 3], default: 0 },
            musicVolume: { type: 'number', title: translate('resources.videoGenerator.musicVolume'), default: 0.4, maximum: 1, minimum: 0 },
        },
        required: [
            "programId",
            "creator",
            "avatar",
            "languages",
            "sessionNumber",
            "introCreditDuration",
            "outroCreditDuration",
            "introAvatarDuration",
            "outroAvatarDuration",
            "musicIntensity",
            "musicVolume"
        ]
    }

    const schemaUI = {
        "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    }


    function submitFormData(data) {
        const components = session.components.map(component => ({
            session: 1,
            pause: component.pause,
            duration: component.duration,
            id: component.exerciseVariantId || component.exerciseId,
            section: component.section,
            speed: component.speedFactor,
            zoom: 'no',
        }));

        const jsonVideoData = { ...data.formData, components, sessionNumber: components ? components[0].session : 0 }
        exportToJSON(jsonVideoData);
    }

    function exportToJSON(valuesToExport) {
        // Create a link to download the stringified object
        const jsonLink = document.createElement('a');
        jsonLink.download = `Session_${session.program}.json`;

        const blob = new Blob([JSON.stringify(valuesToExport)], { type: "application/json" });
        jsonLink.href = URL.createObjectURL(blob);

        // Add and trigger the link
        document.body.appendChild(jsonLink);
        jsonLink.click();

        // Clean the link element
        document.body.removeChild(jsonLink);
    }

    return (
        <div>
            <Tooltip title={translate('resources.videoGenerator.title')} arrow  placement="left">
                <Button variant='contained' color='primary' sx={{ minWidth: 0, padding: 1 }} onClick={() => setOpenJSONExport(true)}>
                    <FileDownload />
                </Button>
            </Tooltip>

            <Dialog open={openJSONExport} maxWidth='lg' fullWidth>
                <DialogContent>
                    <Form
                        schema={formSchema}
                        uiSchema={schemaUI}
                        validator={validator}
                        onSubmit={(data) => submitFormData(data)}
                    >
                        <DialogActions>
                            <Button variant='contained' type='submit'>
                                {translate('resources.videoGenerator.submit')}
                            </Button>
                            <Button variant='outlined' color='error' onClick={() => setOpenJSONExport(false)}>{translate('resources.misc.cancel')}</Button>
                        </DialogActions>
                    </Form>
                </DialogContent>
            </Dialog>
        </div>
    )
}